import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { color3 } from "../componentes/config.js";
import { FaHelmetSafety } from "react-icons/fa6";
import { color1 } from "../componentes/config.js";

const LineaTiempo = () => {
  const dibujar = (destino, titulo, img) => {
    return (
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          variant="body2"
          color="white"
        >
          {" "}
          {destino}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            {img === "1" ? <AccountCircleIcon /> : <FaHelmetSafety />}
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span" style={{ color: color3 }}>
            {titulo}
          </Typography>
          {/* <Typography style={{ color: color1 }}> {subtitulo}</Typography> */}
        </TimelineContent>
      </TimelineItem>
    );
  };

  return (
    <>
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="white"
          >
            <strong>CLIENTE</strong>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
              <AccountCircleIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span" style={{ color: color3 }}>
              Solicita una solución a su necesidad.
            </Typography>
            {/* <Typography>Because you need strength</Typography> */}
          </TimelineContent>
        </TimelineItem>

        {dibujar(
          "PRESTADOR",
          " Recibe necesida del cliente y envía propuesta de valor",
          "2"
        )}
        {dibujar(
          "CLIENTE",
          "Evalua propuestas recibidas y define a quien contratar.",
          "1"
        )}
        {dibujar(
          "PRESTADOR",
          "Recibe aceptación de su propuesta y se traslada hasta la dirección del cliente en la fecha y hora indicada",
          "2"
        )}
        {dibujar(
          "CLIENTE",
          "Espera la llegada del Prestador, quien procede a dar solución a su necesidad.",
          "1"
        )}
        {dibujar(
          "PRESTADOR",
          "Realiza su trabajo de forma segura, garantiza la entrega y verifica el pago por parte del cliente",
          "2"
        )}
        {dibujar(
          "CLIENTE",
          "Paga la tarifa pactada y Califica al Prestador por su servicio!",
          "1"
        )}
        {dibujar("PRESTADOR", "Calificación al Cliente.", "2")}
      </Timeline>
    </>
  );
};

export default LineaTiempo;
