import acercade from "../assets/galeria/acercade.webp";

const Acercade = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6">
          <h1 className="text-muted">¿Quienes somos?</h1>
          <img
            src={acercade}
            className="img-fluid"
            style={{ borderRadius: 20 }}
            alt="acerca de"
          />
        </div>
        <div className="col-sm-6">
          <h5 className="text-muted mt-5">
            En RedeWork, entendemos las necesidades únicas de nuestros clientes
            y nos dedicamos a brindar servicios confiables y flexibles tanto en
            hogares como en empresas.
          </h5>
          <h5 className="text-muted">
            Nuestra empresa nació de la necesidad de proporcionar servicios en
            los momentos en que nuestros clientes tienen el tiempo para atender
            al prestador de servicio, ofreciendo la comodidad de elegir días y
            horas que se adapten a sus horarios.
          </h5>
          <h5 className="text-muted">
            Nuestro objetivo es ofrecer tranquilidad y seguridad a nuestros
            usuarios, garantizando que cada prestador de servicios que ingresa a
            su vivienda o empresa esté debidamente registrado en nuestro portal.
          </h5>
          <h5 className="text-muted">
            Con RedeWork, puede estar seguro de que está recibiendo un servicio
            de calidad, brindado por profesionales verificados y confiables.
          </h5>
          <h5 className="text-muted">
            Nos enorgullecemos de ser la solución práctica y segura para todas
            sus necesidades de servicios, adaptándonos a su disponibilidad y
            asegurando su tranquilidad en cada paso del proceso.
          </h5>
          <h4>En RedeWork, "Somos Servicio"</h4>
        </div>
      </div>
    </div>
  );
};

export default Acercade;
