import React, { useState, useEffect } from "react";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { API_KEY } from "./config";

const MapView = (props) => {
  const [center, setCenter] = useState({ lat: -3.745, lng: -38.523 });
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      setCenter({ lat: latitude, lng: longitude });
      console.log("Encontró coordenadas");
      console.log(latitude, longitude);
    });
  }, []);

  const handleMapClick = (event) => {
    const newCoords = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setCenter(newCoords);
    fetchLocationData(newCoords);
    console.log("Nuevas coordenadas");
    console.log(newCoords);
  };

  const fetchLocationData = async (coords) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.lat},${coords.lng}&key=YOUR_API_KEY`
    );
    const data = await response.json();
    console.log("Data");
    console.log(data);
    if (data.results.length > 0) {
      const location = data.results[0];
      props.Ubicacion({
        Municipio: location.address_components.find((comp) =>
          comp.types.includes("locality")
        )?.long_name,
        Direccion: location.formatted_address,
        Lat: coords.lat,
        Lon: coords.lng,
      });
      console.log("Nueva ubicación:", {
        lat: coords.lat,
        lng: coords.lng,
        address: location.formatted_address,
        country: location.address_components.find((comp) =>
          comp.types.includes("country")
        )?.long_name,
        state: location.address_components.find((comp) =>
          comp.types.includes("administrative_area_level_1")
        )?.long_name,
        city: location.address_components.find((comp) =>
          comp.types.includes("locality")
        )?.long_name,
      });
    } else {
      const location = data.results[0];
      props.Ubicacion({
        Municipio: "",
        Direccion: "",
        Lat: coords.lat,
        Lon: coords.lng,
      });
      console.log(
        "No se encontró información para las coordenadas proporcionadas."
      );
    }
  };

  if (!isLoaded) return <div>Cargando...</div>;

  return (
    <GoogleMap
      mapContainerStyle={{ width: "100%", height: "80vh" }}
      zoom={10}
      center={center}
      mapContainerClassName="map-container"
      onClick={handleMapClick}
    >
      <MarkerF position={center} />
    </GoogleMap>
  );
};

export default MapView;

// import React, { useEffect } from "react";
// import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import { useState } from "react";
// import { useMapEvents } from "react-leaflet";

// const MapView = (props) => {
//   const myApiKey = "AIzaSyAIiy0VJBq-col1gOu2I6XSiQFtYJrWNK4";
//   const [latitud, setLatitud] = useState(0);
//   const [longitud, setLongitud] = useState(0);
//   const [position, setPosition] = useState(null);
//   let tamanio = props.Tamanio;

//   const enviarCoordenadas = (latlng) => {
//     // props.Coordenadas(latitud, longitud)
//     return new Promise((resolve, reject) => {
//       fetch(
//         `https://maps.googleapis.com/maps/api/geocode/json?address=${latlng.lat},${latlng.lng}&key=${myApiKey}`
//       )
//         .then((response) => response.json())
//         .then((responseJson) => {
//           if (responseJson.status === "OK") {
//             props.Ubicacion({
//               Municipio:
//                 responseJson?.results?.[0].address_components?.[0].long_name,
//               Direccion: responseJson?.results?.[0].formatted_address,
//               Lat: responseJson?.results?.[0].geometry.location.lat,
//               Lon: responseJson?.results?.[0].geometry.location.lng,
//             });

//             resolve(responseJson?.results?.[0]);
//           } else if (responseJson.status === "ZERO_RESULTS") {
//             resolve("NO Encontró la dirección");
//             //reject(responseJson.status);
//           }
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     });
//   };

//   const LocationMarker = () => {
//     const map = useMapEvents({
//       click() {
//         map.locate();
//       },
//       locationfound(e) {
//         setPosition(e.latlng);
//         setLatitud(e.latlng.lat);
//         setLongitud(e.latlng.lng);
//         enviarCoordenadas(e.latlng);
//         map.flyTo(e.latlng, map.getZoom());
//       },
//     });

//     return position === null ? null : (
//       <Marker position={position}>
//         <Popup>
//           Usted esta aqui! <br></br> Latitud: {position.lat} Longitud:{" "}
//           {position.lng}
//         </Popup>
//       </Marker>
//     );
//   };

//   return (
//     <div>
//       <MapContainer
//         center={{ lat: latitud, lon: longitud }}
//         zoom={13}
//         style={{ height: tamanio + "vh" }}
//       >
//         <TileLayer
//           attribution='&copy;
//           <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//         />
//         <LocationMarker />
//       </MapContainer>
//     </div>
//   );
// };

// export default MapView;
