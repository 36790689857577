import img1 from "../assets/galeria/aireacondicionado.webp";
import img2 from "../assets/galeria/aireacondicionado1.webp";
import img3 from "../assets/galeria/albanil.webp";
import img4 from "../assets/galeria/conductora.webp";
import img5 from "../assets/galeria/electricista.webp";
import img6 from "../assets/galeria/encomiendas.webp";
import img7 from "../assets/galeria/gallery1.webp";
import img8 from "../assets/galeria/mecanico.webp";
import img9 from "../assets/galeria/mensajero.webp";
import img10 from "../assets/galeria/pisos.webp";
import img11 from "../assets/galeria/plomero.webp";
import img12 from "../assets/galeria/ingeniero.webp";
import img13 from "../assets/galeria/abogado.webp";

const Galeria = () => {
  return (
    <div className="container">
      <div className="row">
        <h1>GALERIA DE IMAGENES DE SERVICIOS</h1>
      </div>
      <div className="row">
        <div
          style={{ alignContent: "center", alignItems: "center" }}
          className="p-3"
        >
          <img
            src={img1}
            style={{ height: 200 }}
            className="img-fluid"
            alt="airea"
          />
          <img
            src={img2}
            style={{ height: 200 }}
            className="img-fluid"
            alt="aireAcondicionado"
          />
          <img
            src={img3}
            style={{ height: 200 }}
            className="img-fluid"
            alt="albañil"
          />
          <img
            src={img4}
            style={{ height: 200 }}
            className="img-fluid"
            alt="conductor"
          />
          <img
            src={img5}
            style={{ height: 200 }}
            className="img-fluid"
            alt="electricista"
          />
          <img
            src={img6}
            style={{ height: 200 }}
            className="img-fluid"
            alt="encomienda"
          />
          <img
            src={img7}
            style={{ height: 200 }}
            className="img-fluid"
            alt="electricista"
          />

          <img
            src={img8}
            style={{ height: 200 }}
            className="img-fluid"
            alt="mecanico"
          />
          <img
            src={img9}
            style={{ height: 200 }}
            className="img-fluid"
            alt="mensajero"
          />
          <img
            src={img10}
            style={{ height: 200 }}
            className="img-fluid"
            alt="pisos"
          />
          <img
            src={img11}
            style={{ height: 200 }}
            className="img-fluid"
            alt="plomero"
          />
          <img
            src={img12}
            style={{ height: 200 }}
            className="img-fluid"
            alt="ingeniero"
          />
          <img
            src={img13}
            style={{ height: 200 }}
            className="img-fluid"
            alt="abogados"
          />
        </div>
      </div>
    </div>
  );
};

export default Galeria;
