import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { color1, color2,  } from './componentes/config.js'
import Menu from './paginas/Menu.jsx';
import Carrusel from './paginas/Carrusel.jsx';
import Galeria from './paginas/Galeria.jsx';
import Registro from './paginas/Registro.jsx';
import Acercade from './paginas/Acercade.jsx';
import Contactenos from './paginas/Contactenos.jsx';
import Pasos from './paginas/Pasos.jsx';
import LineaTiempo from './paginas/LineaTiempo.jsx';
import Politicas from './paginas/Politicas.jsx'
import Pie from './paginas/PiePagina.jsx';



function App() {
  return (
    
    <div className="container-fluid" id='div_menu'>
      <div className="row">
        <Menu/>
      </div>
      <div className="row" id='div_slider'>
        <Carrusel/>
      </div>


      <div 
        className="row" 
        style={{ backgroundColor: color1, padding:100}} 
        id='div_pasos'>
        <Pasos />
      </div>
       

      <div 
        className="row text-center mt-3 mb-3" 
        id='div_lineaTiempo' 
        style={{ backgroundColor: color2, padding:20}}>
        <hr />
        <h2 className='mt-3' style={{ color:'#7ff'}}>LINEA DE TIEMPO DEL SERVICIO</h2>
        <hr />
        <LineaTiempo />
      </div>

      <div 
        className="row text-center mt-3 mb-3" 
        id='div_galeria' 
        style={{ padding:20}}>
        <Galeria />
      </div>

      <div 
        className="row shadow-lg p-3 mb-5 bg-body-tertiary rounded text-center pt-3 mt-3" 
        id='div_registro' 
      >
        <Registro />
      </div>

      <div 
        className="row shadow-lg p-3 mb-5 bg-body-tertiary rounded text-center pt-3 mt-3" 
        id='div_acercade' 
      >
        <Acercade />
      </div>
      

      <div 
        className="row text-center pt-3 mt-3 mb-5" 
        id='div_contactenos' 
        style={{ padding:20}}>
        <Contactenos />
      </div>

      <div 
        className="row text-center" 
        id='div_politicas'  
        style={{ backgroundColor: color1, padding:20}}>
        <Politicas />
      </div>  

      <div className="row" style={{ backgroundColor: color2}}>
        <Pie />
      </div>
    </div>
  );
}


export default App;