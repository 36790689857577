import axios from 'axios'
import { RUTA_SERVER } from './config.js';

/** Esta API recibe todas las peticiones 
 ** de las paginas del Cliente y se comunica
 ** con EL SERVIDOR del Backend, específicamente
 ** con el archivo de rutas.js
 **
***************************** Peticiones GET para consultas *******************************************/

export const sql = async (url, metodo, cuerpo) =>{
  try {
    const reg = await fetch(RUTA_SERVER + url, {
      method: metodo,     //GET, POST, PUT   
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(cuerpo),
    });

    const lista = await reg.json();
    return lista;

  } catch (error) {
    return error.message;
    }
}

/** Esta ruta sirve para Autenticarse **/
 export const getAcceso = async (datos) =>{
  try {
    const resp = await axios.post(RUTA_SERVER+'/getAcceso', datos)
    return resp

  } catch (error) {
    console.log("Ocurrio un error en la API al Autenticarse", error)
    return error.message
  }
} 

export const getCombos = async (datos) =>{
  try {
    const resp = await axios.post(RUTA_SERVER+'/getCombos', datos)
    return resp

  } catch (error) {
    console.log("Ocurrio un error en la API al cargar un Combo", error)
    return error.message
  }
} 

export const getRutaImagenes= async (datos) =>{
  try {
    const resp = await axios.post(RUTA_SERVER+'/getRutaImagenes', datos)
    return resp

  } catch (error) {
    console.log("Ocurrio un error al consultar las Imágenes", error)
    return error.message
  }
}



export const updateCampo= async (datos) =>{
  try {
    const resp = await axios.put(RUTA_SERVER+'/updateCampo', datos)
    return resp

  } catch (error) {
    return(error.response.status);
  }
}

export const updatePersona= async (datos) =>{
  try {
    const resp = await axios.put(RUTA_SERVER+'/updatePersona', datos)
    return resp

  } catch (error) {
    return(error.response.status);
  }
}

export const updateImagen= async (datos) =>{
  try {
    const resp = await axios.post(RUTA_SERVER+'/updateImagen', datos)
    return resp

  } catch (error) {
    return(error.response.status);
  }
}
