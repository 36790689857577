export const TITULO = 'RedeWork!';
export const VERSION = '1.5';
export const PORT_SERVER = 4000;
export const color1 = '#E5E5E5';
export const color2 = '#3a7ca5';
export const color3 = '#7fff00';  //Verde
export const playStore = 'https://play.google.com/store/apps/details?id=com.taxysmoto.movil'
export const API_KEY = 'AIzaSyDasCC-mG1SOtt8yTLKvHFzImxy2kflmN0';
// export const RUTA_SERVER = 'http://192.168.100.22:4000/';
export const RUTA_SERVER = 'https://backredework.onrender.com/';

 

  