import plomero from "../assets/carrusel/plomero.webp";
import albanil from "../assets/carrusel/albanil.webp";
import desarrollador from "../assets/carrusel/desarrollador.webp";
import disenio from "../assets/carrusel/disenio.webp";
import jardinero from "../assets/carrusel/jardinero.webp";
import pintor from "../assets/carrusel/pintor.webp";

const Carrusel = () => {
  return (
    <div id="carouselExampleCaptions" className="carousel slide">
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="4"
          aria-label="Slide 5"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="5"
          aria-label="Slide 6"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img
            src={plomero}
            className="d-block w-100"
            alt="plomeros"
            style={{ height: 800 }}
          />
          <div className="carousel-caption d-none d-md-block">
            <h4>PLOMEROS</h4>
            <h5>¿Tiene un problema de plomeria?</h5>
            <p>¡Nosotros le damos solución!</p>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src={albanil}
            className="d-block w-100"
            alt="albañil"
            style={{ height: 800 }}
          />
          <div className="carousel-caption d-none d-md-block">
            <h4>ALBAÑILES</h4>
            <h5>Contrate personal calificado para hacer o reparar su casa!</h5>
          </div>
        </div>
        <div className="carousel-item">
          <img src={desarrollador} className="d-block w-100" alt="..." />
          <div className="carousel-caption d-none d-md-block">
            <h4>DESARROLLADORES DE SOFTWARE</h4>
            <h5>
              Personal experto y calificado para desarrollo web, movil y de
              escritorio
            </h5>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src={disenio}
            className="d-block w-100"
            alt="diseño gráfico"
            style={{ height: 800 }}
          />
          <div className="carousel-caption d-none d-md-block">
            <h4>DISEÑO GRAFICO</h4>
            <h5>
              Excelentes profesionales que dan solución inmediata a su
              necesidad!
            </h5>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src={jardinero}
            className="d-block w-100"
            alt="jardinero"
            style={{ height: 800 }}
          />
          <div className="carousel-caption d-none d-md-block">
            <h5>JARDINEROS</h5>
            <p>Mantenga su jardin limpio y bonito.</p>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src={pintor}
            className="d-block w-100"
            alt="pintores"
            style={{ height: 800 }}
          />
          <div className="carousel-caption d-none d-md-block">
            <h5>PINTORES</h5>
            <p>Excelente pintores con profesionalismo!.</p>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Anterior</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Siguiente</span>
      </button>
    </div>
  );
};

export default Carrusel;
