import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TITULO } from "../componentes/config.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const MsgModal = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    props.handleClose();
    setOpen(false);
  };

  const renderList = props.msg.map((item, index) => (
    <div key={index}>{item}</div>
  ));

  useEffect(() => {
    if (props.show === true) {
      handleOpen();
    } else {
      props.handleClose();
      handleClose();
    }
  }, [props]);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {" "}
            {" " + TITULO}
          </Typography>
          <div className="row">
            <ul>{renderList}</ul>
          </div>
          <br />
          <button className="btn btn-secondary" onClick={handleClose}>
            Cerrar
          </button>
        </Box>
      </Modal>
    </div>
  );
};
export default MsgModal;
