import Logo from "../assets/logos/logo.webp";
import { playStore } from "../componentes/config.js";
import { FaWhatsapp } from "react-icons/fa";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import Button from "@mui/material/Button";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";

const Menu = () => {
  const tab = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;

  const ingresar = () => {
    window.open("https://soft.redework.xyz/");
  };

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <img
          src={Logo}
          style={{ height: 100 }}
          className="img-fluid"
          alt="logo"
        />
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          <a className="navbar-brand" href="#div_pasos">
            ¿Cómo funciona?
          </a>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a
                className="nav-link active"
                aria-current="page"
                href="#div_lineaTiempo"
              >
                ¿Cómo utilizar el servicio?
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#div_registro">
                Registrarme
              </a>
            </li>
          </ul>
          <form className="d-flex" role="search">
            <a
              className="nav-link"
              href="https://api.whatsapp.com/send?phone=573028551507&amp;text=Hola,%20requiero%20mas%20información..."
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <FaWhatsapp /> WhatsApp
            </a>
            {tab}
            <a className="nav-link" href="#div_contactenos">
              <ForwardToInboxIcon /> Contactenos
            </a>
            {tab}
            {/* <a
              className="nav-link"
              href={playStore}
              target="_blank"
              rel="noreferrer"
            >
              <PhoneIphoneIcon /> Descargar la App
            </a> */}
            {tab}
            <Button
              variant="contained"
              color="secondary"
              endIcon={<MeetingRoomIcon />}
              onClick={ingresar}
            >
              Ingresar
            </Button>
          </form>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
