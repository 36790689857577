import { useEffect, useState } from "react";
import { RUTA_SERVER } from "../componentes/config.js";
import { sql } from "../componentes/api.js";
import MsgModal from "./MsgModal.jsx";
import MapView from "../componentes/MapView.jsx";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

function Registro() {
  const [enviado, setEnviado] = useState(false);
  const [municipios, setMunicipios] = useState([]);
  const [datos, setDatos] = useState({
    Cedula: "",
    Nbres: "",
    Foto: "",
    Municipio: 0,
    Lat: "",
    Lon: "",
    Direccion: "",
    Celular: "",
    Correo: "",
    Clave: "",
  });
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState([]);
  const handleClose = () => setShow(false);

  useEffect(() => {
    const cargarMunicipios = async () => {
      try {
        const lista = await sql("getCombo", "POST", {
          Tabla: "municipios",
          Campo: "Activo",
          Valor: "1",
        });
        setMunicipios(lista);
      } catch (error) {
        mostrarMsg(["Ocurrió un Error al cargar los Municipios!"]);
      }
    };
    cargarMunicipios();
  }, []);

  const asignarDato = (event) => {
    event.preventDefault();
    setDatos({ ...datos, [event.target.name]: event.target.value });
  };

  const grabar = async () => {
    try {
      const idReg = await sql("setPersona", "POST", datos);
      setEnviado(false);
      window.open("https://soft.redework.xyz/");
      mostrarMsg([
        "Felicidades " + datos.Nbres + "!!",
        "Gracias por registrase con nosotros",
        "Recuerde que esta plataforma puede abrirla desde su computador, celular o tableta, pues ella se adapta a la pantalla del dispositivo desde el cual este accediendo.",
        "Próntamente podrá descargar la App para uso en el Celular!!.",
      ]);
    } catch (error) {
      setEnviado(false);
      mostrarMsg(["Ocurrió un problema al Grabar los datos!!"]);
    }
  };

  const ingresar = () => {
    window.open("https://soft.redework.xyz/");
  };

  const mostrarMsg = (msg) => {
    setMsg(msg);
    setShow(true);
  };

  const Ubicacion = async (reg) => {
    try {
      const id = await sql("getId", "POST", {
        Tabla: "municipios",
        Campo: "Nbre",
        Valor: reg.Municipio.toUpperCase(),
      });

      if (id !== null && id.length > 0) {
        setDatos({
          ...datos,
          Municipio: id[0].Id,
          Direccion: reg.Direccion,
          Lat: reg.Lat,
          Lon: reg.Lon,
        });
      } else {
        mostrarMsg(["No se pudo encontrar el Municipio."]);
      }
    } catch (error) {
      mostrarMsg(["Ocurrió un problema consultar los datos de Ubicación!!"]);
    }
  };

  const validarDatos = () => {
    setEnviado(true);
    if (datos.Cedula === "") {
      mostrarMsg(["Por favor digite su número de Cédula!"]);
      setEnviado(false);
    } else if (datos.Nbres === "") {
      mostrarMsg(["Por favor digite su Nombre!"]);
      setEnviado(false);
    } else if (datos.Municipio === "") {
      mostrarMsg([
        "Por favor haga Click en el mapa para identificar su ubicación\nSi la ubicada por el sistema no es la suya, no importa, después pdrá actualizarla!",
      ]);
      setEnviado(false);
    } else if (datos.Direccion === "") {
      mostrarMsg(["Por favor digite su Dirección de residencia!"]);
      setEnviado(false);
    } else if (datos.Celular === "") {
      mostrarMsg(["Por favor digite un Número de CeluLar!"]);
      setEnviado(false);
    } else if (datos.Correo === "") {
      mostrarMsg(["Por favor digite un Correo electrónico!"]);
      setEnviado(false);
    } else if (datos.Clave === "") {
      mostrarMsg(["Por favor cree una Clave de acceso a la plataforma!"]);
      setEnviado(false);
    } else {
      validarCedula();
    }
  };

  const validarCedula = async () => {
    try {
      const idReg = await fetch(RUTA_SERVER + "getId", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Tabla: "personas",
          Campo: "Cedula",
          Valor: datos.Cedula,
        }),
      });

      const id = await idReg.json();
      if (id === null || id.length == 0) {
        grabar();
      } else {
        setEnviado(false);
        mostrarMsg([
          "El número de Cédula " +
            datos.Cedula +
            ", ya se encuentra en nuestro sistema.",
          "Por favor revise los datos o solicite recuperación de su cuenta.",
        ]);
      }
    } catch (error) {
      mostrarMsg(["Ocurrió un problema al Grabar los datos!!"]);
    }
  };

  return (
    <div className="container mt-3 mb-3">
      <div className="row">
        <h3>REGISTRO DE USUARIOS</h3>
        <p>
          Si su ubicación no es la que muestra el mapa, por favor búsquela
          dentro del mapa y haga click para señalarla!
        </p>
        <hr />
      </div>

      <div className="row mb-3">
        <MapView Tamanio={"50"} Ubicacion={Ubicacion} />
      </div>

      <hr />
      <p>Por favor digite todos los datos solicitados en este formulario!</p>
      <div className="row">
        <div className="col-sm-4">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              name="Cedula"
              placeholder="Digite su Número de Cédula"
              onChange={(event) => asignarDato(event)}
            />
            <label htmlFor="Cedula">Digite su Número de Cédula</label>
          </div>
        </div>

        <div className="col-sm-8">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              name="Nbres"
              placeholder="Digite sus Nombres"
              onChange={(event) => asignarDato(event)}
            />
            <label htmlFor="Nbres">Nombres</label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-4">
          <div className="form-floating mb-3">
            <select
              className="form-select"
              aria-label="Default select example"
              value={datos.Municipio}
              name="Municipio"
              onChange={(event) => asignarDato(event)}
            >
              <option value={0}>Seleccione un Municipio</option>
              {municipios.map((reg) => {
                return (
                  <option key={reg.Id} value={reg.Id}>
                    {reg.Nbre}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="col-sm-8">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              name="Direccion"
              placeholder="Digite su Dirección"
              onChange={(event) => asignarDato(event)}
              value={datos.Direccion}
            />
            <label htmlFor="Direccion">Dirección</label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-4">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              name="Celular"
              placeholder="Digite su número de Celular"
              onChange={(event) => asignarDato(event)}
            />
            <label htmlFor="Celular">Celular</label>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              name="Correo"
              placeholder="Digite su Correo Electrónico"
              onChange={(event) => asignarDato(event)}
            />
            <label htmlFor="Correo">Correo Electrónico</label>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control"
              name="Clave"
              placeholder="Cree una clave"
              onChange={(event) => asignarDato(event)}
            />
            <label htmlFor="Clave">Cree una Clave de Acceso</label>
          </div>
        </div>
      </div>

      <hr />
      <div className="row">
        <div className="mb-3">
          {enviado === false ? (
            <button
              className="btn btn-primary"
              disabled={!enviado === false}
              color="#7ff000"
              onClick={(event) => validarDatos(event)}
            >
              Registrar mis datos
            </button>
          ) : (
            <button className="btn btn-primary">
              Espere un momento por favor. Estamos grabando su información...{" "}
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Grabando...</span>
              </div>
            </button>
          )}
        </div>
      </div>

      <div className="row">
        <div className="shadow-lg p-3 mb-5 bg-body-tertiary rounded">
          <h5>
            ¿Ya se encuentra registrado?{" "}
            <a href="#" onClick={ingresar}>
              entonces ingrese a la plataforma haciendo click aquí{" "}
              <KeyboardArrowRightIcon />
            </a>
          </h5>
        </div>
      </div>
      <MsgModal show={show} handleClose={handleClose} msg={msg} />
    </div>
  );
}

export default Registro;
