import { useState } from "react";
import { RUTA_SERVER } from "../componentes/config.js";
import MsgModal from "./MsgModal.jsx";

function Contactenos() {
  const [enviado, setEnviado] = useState(false);
  const [activo, setActivo] = useState(true);
  const [datos, setDatos] = useState({
    Nbres: "",
    Correo: "",
    Celular: "",
    Asunto: "",
    Mensaje: "",
  });
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState([]);
  const handleClose = () => setShow(false);

  const asignarDato = (event) => {
    event.preventDefault();
    setDatos({ ...datos, [event.target.name]: event.target.value });
  };

  const grabarContactenos = async () => {
    try {
      setActivo(true);
      const idReg = await fetch(RUTA_SERVER + "setContactenos", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datos),
      });

      const ticket = await idReg.json();
      setEnviado(false);
      setActivo(false);
      mostrarMsg([
        "Su mensaje se registró con Ticket # " + ticket.Id,
        "Usted es muy importante para nosotros.",
        "Próntamente le daremos contestación a su mensaje.",
        "...",
        "Gracias por contáctarnos!",
      ]);
    } catch (error) {
      setEnviado(false);
      setActivo(true);
      mostrarMsg(["Ocurrió un problema al Grabar el mensaje!!"]);
    }
  };

  const mostrarMsg = (msg) => {
    setMsg(msg);
    setShow(true);
  };

  const validarDatos = () => {
    console.log("Validara");
    setEnviado(true);
    if (datos.Nbres === "") {
      mostrarMsg([
        "Por favor digite sus Nombres para saber quien nos escribe!",
      ]);
      setEnviado(false);
    } else if (datos.Correo === "") {
      mostrarMsg(["Por favor digite un Correo electrónico para contactarlo!"]);
      setEnviado(false);
    } else if (datos.Celular === "") {
      mostrarMsg(["Por favor digite un Número de CeluLar para contactarlo!"]);
      setEnviado(false);
    } else if (datos.Asunto === "") {
      mostrarMsg(["Por favor digite el Asunto del mensaje!"]);
      setEnviado(false);
    } else if (datos.Mensaje === "") {
      mostrarMsg([
        "Por favor digite el Detalle del mensaje que desea enviarnos!",
      ]);
      setEnviado(false);
    } else {
      grabarContactenos();
    }
  };

  return (
    <div className="container mt-3 mb-3">
      <div className="row">
        <h3 style={{ color: "#000050" }}>CONTACTENOS</h3>
        <p> Por favor digite todos los datos solicitados en este formulario!</p>
        <hr />
      </div>

      <div className="row">
        <div className="col-sm-4">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              name="Nbres"
              placeholder="Digite sus Nombres"
              onChange={(event) => asignarDato(event)}
            />
            <label htmlFor="Nbres">Nombres y Apellidos</label>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              name="Correo"
              placeholder="Digite su Correo Electrónico"
              onChange={(event) => asignarDato(event)}
            />
            <label htmlFor="Correo">Correo Electrónico</label>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              name="Celular"
              placeholder="Digite un Celular de contacto"
              onChange={(event) => asignarDato(event)}
            />
            <label htmlFor="Celular">Número de Celular</label>
          </div>
        </div>

        <div className="row">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              name="Asunto"
              placeholder="Asunto del mensaje"
              onChange={(event) => asignarDato(event)}
            />
            <label htmlFor="Asunto">Digite el Asunto</label>
          </div>
        </div>

        <div className="row">
          <div className="form-floating mb-3">
            <textarea
              type="text"
              className="form-control"
              name="Mensaje"
              placeholder="Digite su mensaje aqui!"
              onChange={(event) => asignarDato(event)}
            />
            <label htmlFor="Mensaje">Escriba su Mensaje</label>
          </div>
        </div>

        <hr />
        <div className="mb-3">
          {enviado === false ? (
            <button
              className="btn btn-primary"
              disabled={activo === false}
              label="Enviar Mensaje"
              color="primary"
              type="submit"
              onClick={(event) => validarDatos(event)}
            >
              Enviar Mensaje
            </button>
          ) : (
            <button className="btn btn-secondary" disabled>
              Espere un momento por favor. Estamos enviando su mensaje...{" "}
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Enviando mensaje...</span>
              </div>
            </button>
          )}
        </div>
      </div>
      <MsgModal show={show} handleClose={handleClose} msg={msg} />
    </div>
  );
}

export default Contactenos;
